*{
    animation-duration: 0.3s;
}

body {
    background-color: #0686d6;
}

h2 {
    background-color: #087CC7;
    width: 200px;
    border: 4px solid white;
    border-radius: 10px;
    padding: 1px;
    color: white;
    text-align: center;

}

h3 {
    inline-size: auto;
}



form[id="hiddeForm"] button {
    background-color: green;
}

form[id="hiddeForm"] button:hover {
    background-color: rgba(0, 173, 0, 0.671);
}

.navbar {
    background-color: blue;
    justify-content: space-between;
}

.LoginTitle {
    background-color: #348EEF;
    color:white;
    transition-duration: 1s;
    font-size: 40px;
    width: 100%;
    margin-bottom: 10px;
    padding-top: 100px;
    font-style: normal;
    font-weight: 200px;
    font-family: "Source Sans Pro";
}

button {
	font:bold 13px Arial;
    background-color: green;
	color:#fff;
    border:none;
    margin: 1%;
    border-radius: 10px;
    padding: 2px;
}

button:hover{
    background-color: rgba(0, 173, 0, 0.671);
}

.selection {
    background-color:  #D5D5D5;
    padding: 10px;
    border-radius: 10px;
    /* height: 100px; */

}

.contain {
    width: 100%;
    height: 100%;
    padding-bottom: 563px;
    padding-top: 0;
    margin-top: 0;
    text-align: center;
    background-color: #2B7FE8;
    display: flex;
    flex-direction: column;
}

.formulaireSession {
    text-align: center;
    width: 20%;
    min-width: 250px;
    border: 1px solid white;
    margin: 1px;
    color: white;
}

.formulaire {

    width: 250px;
    margin: 0 auto 10px auto;
    padding: 10px 15px;
    border :1px solid rgba(255, 255, 255, 0.4);
    background-color:#83B8F4 ;
    color: white;
    font-size: 18px;
    box-sizing: border-box;
    text-align: center;
}

.formulaire input {
    margin: 5px;
}

.formulaireSession input{
    color:black;

}

.formulaireSession select{
    color:black
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    /* opacity: 1; Firefox */
  }


.deconnection {
    background-color: red;
    font-size: large;
    

}

button {
    padding: 10px;
}

h3 {
    font-size: 25px;
}

h1 {
    color: white;
}